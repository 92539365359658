<template slot-scope="props">
  <div>
    <b-table class="table is-fullwidth" :data="companyUsers" paginated default-sort-direction="asc">
      <b-table-column label="ID" width="40" numeric v-slot="props"
        >{{ props.index + 1 }}
      </b-table-column>

      <b-table-column sortable field="nome" label="Usuário" v-slot="props">
        <div v-if="props.row.pending">
          <strong>{{ props.row.invitationEmail }}</strong>
          <b-tag type="is-warning tag-pendente">Pendente</b-tag>
        </div>

        <div v-else>
          <strong>{{ props.row.name }}</strong>
          <small class="tag-email"
            ><i>{{ props.row.invitationEmail }}</i></small
          >
        </div>
      </b-table-column>

      <b-table-column
        centered
        sortable
        field="createdAt"
        label="Inserido em"
        width="140"
        v-slot="props"
        >{{ new Date(props.row.createdAt).toLocaleDateString('pt-BR') }}</b-table-column
      >

      <b-table-column sortable field="function" label="Função" width="200" v-slot="props">{{
        props.row.function
      }}</b-table-column>
    </b-table>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { searchUser } from '@/resources/users/index';

export default {
  name: 'ViewCompanyUsers',
  data() {
    const data = {
      user: 0,
      searching: false,
      userName: '',
    };

    return {
      data,
    };
  },
  computed: {
    ...mapState('companies', ['companyHistory']),
    ...mapGetters({
      companyUsers: 'getCompanyUsers',
    }),
  },
  watch: {
    companyHistory() {
      if (this.companyHistory.id !== 0) this.getUsers(this.companyHistory.companyId);
    },
  },
  methods: {
    ...mapActions(['getUsers']),
    fetchAllUsers: debounce(async function obterPesquisa() {
      this.data.searching = true;
      try {
        const pesquisa = await searchUser(this.data.userName);
        Vue.set(this, 'pesquisa', pesquisa.data);
      } catch (error) {
        this.$alerta(error.message, 'is-danger');
      }
      this.data.searching = false;
    }, 500),
  },
};
</script>
